




























































































import Vue from 'vue'
import SearchIcon from '@/assets/icons/search.svg'
import VTable from '@/components/VTable/VTable.vue'
import VButton from '@/components/VButton.vue'
import ToasSucces from '@/components/ToasSucces.vue'
import StateFilter from '@/components/StateFilter.vue'
import InvitationModal from '../admin/components/InvitationModal.vue'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { EMPTY_TABLE_MESSAGE } from '@/interfaces'
import VTablePagination from '@/components/VTable/VTablePagination.vue'

export default Vue.extend({
  components:{
    SearchIcon,
    VTable,
    VButton,
    StateFilter,
    InvitationModal,
    ToasSucces,
    VTablePagination
  },

  data(this: any){
    return {
      search: '',
      appendParams: {},
      emptyDataMessage: EMPTY_TABLE_MESSAGE.default,
      columns: [
        {
          name: 'createdAt',
          title: 'Date',
          transform: ({createdAt}: any) =>{
            return this.luxonFormat(createdAt, DateTime.DATETIME_SHORT)
          }
        },
        {
          name: 'ip', title: 'Adresse IP'
        },
        {
          name: 'causer',
          title: 'Utilisateur',
          transform:({causer}: any) =>{

            return causer.firstName + ' '+ causer.lastName
          }
        },
        {
          name: 'causer.roles',
          title: 'Roles',
          width: '18%',
          transform({causer}: any){
            const roles = causer.roles

            const rolesNames: string[] = roles.map((role: any) => {
              return role.name
            })
            return rolesNames.toString()
          }
        },
        {name: '__slot:action'}
      ],

      columnsMobile: [
        {
          name: 'createdAt',
          title: 'Date',
          transform({createdAt}: any){
            return createdAt
          }
        },
      ],
      activities: [],
      modalActionComponent: {
        name: '',
        props:{}
      },
      modalSize: 'md',
      showModalAction: false,
      toast: false,
      sendTo: ''
    }
  },

  methods:{

    onChangePage(page: string | number) {
      console.log('change-page', page);

      //@ts-ignore
      this.$refs.table.changePage(page)
    },

    formateDate(date: any){
      if (!date) {
        return ''
        }
      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATE_MED)
    },


    onPaginationData(paginationData: any){
      console.log('Operation paginationData: ====>', paginationData);
      //@ts-ignore
      this.$refs.tablePagination.setPagination(paginationData)
    },

    loadActivityOnApiMode(this: any,{options}: any){
      const params = options['params']
      return new Promise((resolve, reject) => {
        this.$store.dispatch('activity/getAllActivity', params)
        .then((response: any) => {
          console.log('activities ', response);

          resolve({data: response})
        })
        .catch((error: any) => {
          console.log('error ', error);
          reject(error)
        })
      })
    },

    onFilteredState(this: any, event: any){
      //
      console.log('event ', event);
      this.appendParams.rechargeStatus = event
      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.filter
      this.refreshTables()
    },

    onResetFilted(this: any){
      this.appendParams.rechargeStatus = ''

      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      this.refreshTables()
    },


    onSearch(this: any, search: string){
      //
      this.appendParams.search = search;
      if (search.length > 0) {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.search
      }else {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      }
      this.refreshTables()
    },


    refreshTables(){
      //@ts-ignore
      this.$refs.table.refresh();
      //@ts-ignore
      this.$refs.mobileTable.refresh()
    },

    showToast(this: any, to: string){
      this.sendTo = to
      this.showModalAction = false
      this.toast = true;
      setTimeout(() => {
        this.toast = false
      }, 4000);
    }

  },

  computed: {
    ...mapGetters('roles',['roles']),
    ...mapGetters('auth',['auth']),

    filterItems(){
      return [
        {
          name: "Role",
          key: 'role',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
        {
          name: 'Utilisateur',
          key: 'user',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
      ]
    }
  },

  mounted(this: any){
    //
  }
})
