<template>
    <div class="bg-green-500  font-semibold flex items-center p-3 rounded-lg fixed  "
    :class="position">
        <span>
            <Icontoasche />
        </span>
        <span class="ml-2 text-white">
            <slot></slot>
        </span>

    </div>
</template>

<script>

import Icontoasche from "@/assets/svg/toascheck.svg?inline";
export default {
    components:{
        Icontoasche
    },
    props: {
        position: {
            type: String,
            default: '-mt-12'
        }
    }
}
</script>

<style scoped>

</style>
