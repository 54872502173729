













































































import VButton from '@/components/VButton.vue'
import { IRole, SendInvitation} from '@/interfaces'
import Vue from 'vue'
interface InvitationModalData {
  invitation: SendInvitation;
  loading: boolean;
  otpLoading: boolean;
  open: boolean;
  roleErrors: string[];
  showOtpModal: boolean;
  otp: any;
}
export default Vue.extend({
  components: { VButton},
  props: {
    admin: {
      type: Object,
      default(){
        return {}
      }
    },

    roles: {
      type: Array,
      default(){
        return {}
      }
    }
  },

  data(): InvitationModalData{
    return {
      invitation:{
        invited_by: this.admin._id,
        email:'',
        role:''
      },
      loading: false,
      otpLoading: false,
      open: false,
      roleErrors: [],
      showOtpModal: false,
      otp: {
        to: this.admin._id,
        accountType: "admin",
      },
    }
  },

  methods: {
    onSubmitInvitationForm(): void{
      // 
      const invitation = this.$data.invitation;
      this.$data.loading = true;
      console.log('invitation',  invitation);
      if (! this.$data.invitation.role) {
        this.$data.roleErrors = []
        this.$data.roleErrors.push('le champs role est obligatoire')
        this.$data.loading = false;
        return
      }
      this.$data.roleErrors = []

      // setTimeout(() => {
      //   this.$data.loading = false
      //   this.$parent.$emit('done', invitation.email)
      // }, 2000);

      // this.$store.dispatch('admins/sendInvitation', {invitation})
      // .then(response => {
      //   console.log('response ', response);
      //   this.$data.toast = true
      //   // this.$data.showOtpModal = false;
      //   this.$parent.$emit('done', invitation.email)
      // })
      // .catch(error => {
      //   console.log('createInvitation error: ', error);
        
      // })
      // .finally(() => {
      //   this.$data.loading = false
      // })

      const otpForm = this.$data.otp
      this.$store.dispatch('admins/sendInvitationOtp', otpForm)
      .then(opt => {
        console.log('otp: ', opt);
        this.$data.showOtpModal = true;
      })
      .catch(error => {
        console.log('send Otp error: ', error);
      })
      .finally(() => {
        this.$data.loading = false
      })
      
    },

    onSubmitCheckOtp(): void{
      const otpForm = this.$data.otp
      this.$data.otpLoading = false
      this.$store.dispatch('admins/checkInvitationOtp', otpForm)
      .then(otp => {
        const code = otp.code;
        const invitation = this.$data.invitation
        this.$data.loading = true
        this.$data.showOtpModal = false;
        this.$store.dispatch('admins/sendInvitation', {invitation, code})
        .then(response => {
          console.log('response ', response);
          this.$data.toast = true
          this.$parent.$emit('done', invitation.email)
        })
        .catch(error => {
          console.log('createInvitation error: ', error);
        })
        .finally(() => {
          this.$data.loading = false
        })
      })
      .catch(error => {
        console.log('checkOtp error: ',error.data);
      })
      .finally(() => {this.$data.otpLoading = false})
    },

    selectRole(role: IRole): void {
      this.$data.invitation.role = role.name
      this.$data.open = false
      this.$data.roleErrors = [];
    },

  },

  
})
